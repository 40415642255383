<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
    <v-layout row wrap>
    <template>
      <v-flex
        sm=12
        xs=12
        md=12
        lg=12
        xl=12
      >
        <v-card>
          
          <v-card-title>
            <v-row>
              <v-col cols=12 md=6 lg=6 xl=6>
                <v-btn-toggle>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="deep-orange darken-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Manager
                    </v-btn>
                  </template>
                  <v-list>
                    <v-subheader>Process to all key</v-subheader>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-avatar
                          size="32px"
                          tile
                        >
                          <img
                            src="/add-clock.png"
                            alt="Add Extra Time"
                          >
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-title>
                        <v-btn
                          :loading="Overlay"
                          :disabled="Overlay"
                          color="blue lighten-2"
                          class="ma-2 white--text"
                          small
                          @click="doAddExtraTimeMenu"
                        >
                          Add Extra Time
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-avatar
                          size="32px"
                          tile
                        >
                          <img
                            src="/images/reset.png"
                            alt="Remove All HWID"
                          >
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-title>
                        <v-btn
                          :loading="Overlay"
                          :disabled="Overlay"
                          color="pink darken-4"
                          class="ma-2 white--text"
                          small
                          @click="doUpdateHwid"
                        >
                          Reset All HWID
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-avatar
                            size="32px"
                            tile
                        >
                          <img
                            src="/images/lock.png"
                            alt="Lock All Key"
                          >
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-title>
                        <v-btn
                            :loading="Overlay"
                            :disabled="Overlay"
                            color="amber darken-4"
                            class="ma-2 white--text"
                            small
                            @click="doBlockAllKey"
                        >
                          Lock All Key
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-avatar
                         size="32px"
                         tile
                        >
                          <img
                           src="/images/unlock.png"
                           alt="Unlock All Key"
                          >
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-title>
                        <v-btn
                         :loading="Overlay"
                         :disabled="Overlay"
                         color="green darken-4"
                         class="ma-2 white--text"
                         small
                         @click="doUnLockAllKey"
                        >
                          Unlock All Key
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                  <v-btn
                    color="primary"
                    dark
                    text
                  >
                    1 HWID
                    <v-icon
                      dark
                      right
                      color="primary"
                    >
                      mdi-monitor
                    </v-icon>
                  </v-btn>
                  <v-btn
                    color="success"
                    dark
                    text
                  >
                    2 HWID
                    <v-icon
                      dark
                      right
                      color="success"
                    >
                      mdi-monitor-multiple
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols=12 md=6 lg=2 xl=2>
                <v-text-field
                  v-model="searchOnData"
                  append-icon="mdi-magnify"
                  label="DATA SEARCH"
                  single-line
                  hide-details
                  @change="doSearchData"
                />
              </v-col>
              <v-col cols=12 md=6 lg=4 xl=4>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title>
            <v-row>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-select
                v-model="showFilter.status"
                :items="FilterUsed"
                item-text="title"
                item-value="search"
                hint="Search by Status"
                persistent-hint />
              </v-col>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-autocomplete
                  v-model="showFilter.username"
                  :items="sellers"
                  hint="Search By Seller"
                  persistent-hint />
              </v-col>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-select
                v-model="showFilter.key_package"
                :items="packages"
                hint="Search by Package"
                persistent-hint />
              </v-col>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-select
                  v-model="showFilter.hwid"
                  :items="FilterHWID"
                  item-text="title"
                  item-value="search"
                  hint="Search by Multi HWID / Single HWID"
                  persistent-hint
                />
              </v-col>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-autocomplete
                    v-model="showFilter.country"
                    :items="Countries"
                    label="Country"
                    clearable
                    hint="Search by Country"
                    persistent-hint
                />
              </v-col>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-select
                  v-model="showFilter.created"
                  :items="FilterCreated"
                  item-text="title"
                  item-value="search"
                  hint="Search by Created Time"
                  persistent-hint
                />
              </v-col>
							<v-col cols=12 md=4 lg=2 xl=2>
								<v-select
									v-model="showFilter.first_login"
									:items="FilterCreated"
									item-text="title"
									item-value="search"
									hint="Search by First Login Time"
									persistent-hint
								/>
							</v-col>
							<v-col cols=12 md=4 lg=2 xl=2>
								<v-autocomplete
									v-model="showFilter.purchaser"
									:items="PurchaserList"
									item-text="username"
									item-value="seller_id"
									hint="Search by Purchaser"
									persistent-hint />
							</v-col>
							<v-col cols=12 md=4 lg=2 xl=2>
								<v-text-field
									v-model="showFilter.playedTime"
									hint="Search by Played time. Ex: 1-10 = 1 to 10 | 10-10 = 10 | 10 >= 10"
									persistent-hint />
							</v-col>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-text-field
                    v-model="showFilter.discord"
                    hint="Search by Discord"
                    persistent-hint
                    clearable
                />
              </v-col>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-text-field
                    v-model="showFilter.ip"
                    hint="Search by IP"
                    persistent-hint
                    clearable
                />
              </v-col>
              <v-col cols=12 md=4 lg=2 xl=2>
                <v-btn
                  :loading="Overlay"
                  :disabled="Overlay"
                  color="blue lighten-3"
                  class="ma-2 white--text"
                  @click="doFilter"
                >
                  Search
                  <v-icon
                    right
                    dark
                  >
                    mdi-crosshairs
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="Items"
            :search="search"
            item-key="key_login"
            :loading="Overlay"
            loading-text="Loading... Please wait"
            :footer-props="{'items-per-page-options':[15,30,50,100]}"
            :items-per-page="15"
            class="with-divider"
            cellClass="with-divider"
          >
            <template v-slot:[`item.country`]="{ item }">
              {{
                item.country ? item.country:'Global'
              }}
              <gb-flag
                  :code="item.country ? item.country.toString().toLowerCase():''"
                  size="mini"
              />
              <br />
              <v-btn
                  class="mx-1 mb-1"
                  dark
                  color="green"
                  v-if="item.country"
                  @click="doSetCountryGlobal(item)"
                  :disabled="setCountryState"
                  :loading="item && setCountryState === item.key_id"
                  small
              >
                Global
              </v-btn>
              <v-btn
                  class="mx-1"
                  dark
                  color="blue"
                  @click="doToggleUpdateCountry(item)"
                  :disabled="setCountryState || (setCountryData.key_id !== null && setCountryData.key_id !== item.key_id)"
                  :loading="setCountryState === item.key_id"
                  small
              >
                Change Country
              </v-btn>
            </template>
            <template v-slot:[`item.purchased_id`]="{ item }">
              <!-- <span v-if="item.purchased_id.length > 0">
                {{ item.purchased_id[0].username }}
              </span> -->
              <v-btn
                text
                dark
                small
								@click="doOpenMovePurchaser(item)"
                v-if="item.purchased_id.length > 0"
              >
              {{ item.purchased_id[0].username }}
              </v-btn>
              <span v-else>
                {{item.key_seller}}
                <p class="red--text">This is not shop key</p>
              </span>
            </template>
            <template v-slot:[`item.protection`]="{ item }">
              <v-btn
                block
                :color="item.protection ? 'green':'red'"
                small
                @click="doChangeProtection(item)"
                class="ma-2"
              >
                PROTECTION: {{ item.protection ? 'ON':'OFF' }}
              </v-btn>
              <v-btn
                  block
                  :color="item.banned ? 'green':'red'"
                  small
                  @click="doChangeBanned(item)"
                  class="ma-2"
              >
                BANNED: {{ item.banned ? 'ON':'OFF' }}
              </v-btn>
              <v-btn
                  block
                  color="blue"
                  small
                  @click="doFetchGameLogs(item.key_id)"
                  class="ma-2"
                  v-if="item.key_details.length !== 0"
              >
                GAME LOGS
              </v-btn>
            </template>
            <template v-slot:[`item.key_login`]="{ item }">
              <div class="mb-2">
<!--                <v-btn-->
<!--                    color="blue"-->
<!--                    small-->
<!--                    @click="doGetData(item.key_id)"-->
<!--                    class="mx-2"-->
<!--                >-->
<!--                  <v-icon-->
<!--                      light-->
<!--                      left-->
<!--                  >-->
<!--                    mdi-check-->
<!--                  </v-icon>-->
<!--                  DATA-->
<!--                </v-btn>-->
                <template v-if="item.key_expired === false">
                    <v-btn
                      text
                      color="success"
                      medium
                      v-clipboard="item.key_login"
                      v-if="item.key_status === 1"
                      >
                      <v-icon left>mdi-check</v-icon>
                        {{item.key_login}}
                      <v-icon right>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn
                        text
                        color="grey"
                        medium
                        v-if="item.key_status !== 1"
                        v-clipboard="item.key_login"
                        >
                      <v-icon left color="error">fas fa-ban</v-icon>
                      <strike>{{item.key_login}}</strike>
                      <v-icon right color="error">mdi-lock</v-icon>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn
                        text
                        :color="item.freeze > 0 ? 'orange':'red'"
                        medium
                        v-clipboard="item.key_login"
                        >
                      <strike v-if="item.freeze === 0">{{item.key_login}}</strike>
											<template v-else>{{item.key_login}}</template>
                      <v-icon right v-if="item.freeze === 0">mdi-update</v-icon>
											<v-icon right v-else>mdi-lock-clock</v-icon>
                    </v-btn>
                </template>
                <v-icon right :color="item.online > 0 ? 'green':'red'">mdi-lightning-bolt-circle</v-icon>
              </div>
              <v-row>
                <br />
                <v-btn
                    white--text
                    text
                    small
                >
                  <v-icon
                      light
                      left
                  >
                    mdi-check
                  </v-icon>
                  Created At: {{ doParseTime(item.key_createdat) }}
                </v-btn>
                <template v-if="item.key_details.length === 0">
                  <br />
                  <v-btn
                      color="success"
                      text
                      small
                  >
                    <v-icon
                        light
                        left
                    >
                      mdi-check
                    </v-icon>
                    KEY UNUSED
                  </v-btn>
                </template>
                <template v-else>
                  <br />
                  <v-btn
                      color="deep-orange darken-1"
                      text
                      small
                  >
                    <v-icon
                        light
                        left
                    >
                      mdi-clock
                    </v-icon>
                    Key Expire: {{ doParseTime(item.key_details[0])}}
                  </v-btn>
                  <br />
                  <v-btn
                      color="cyan darken-1"
                      text
                      small
                  >
                    <v-icon
                        light
                        left
                    >
                      mdi-clock
                    </v-icon>
                    First Login: {{ doParseTime(item.key_details[1])}}
                  </v-btn>
                  <br />
                  <v-btn
                      color="blue darken-1"
                      text
                      small
                  >
                    <v-icon
                        light
                        left
                    >
                      mdi-clock
                    </v-icon>
                    Last Login: {{ doParseTime(item.key_details[2])}}
                  </v-btn>
                  <br />
                  <v-btn
                      color="white"
                      text
                      small
                      v-if="item.playedTime > 0"
                  >
                    <v-icon
                        light
                        left
                    >
                      mdi-clock
                    </v-icon>
                    Played Time: {{ item.playedTime }}
                  </v-btn>
                </template>
              </v-row>
                <br />
              <v-row>
									<v-col cols="4" v-if="item.key_details.length > 0">
										<v-btn
											value="justify"
											color="blue lighten-2"
											small
											@click="doFreeze(item.key_id)"
											v-if="item.freeze === 0"
										>
											<v-icon
												light
												left
											>
												mdi-lock-clock
											</v-icon>
											Freeze
										</v-btn>
										<v-btn
											value="justify"
											color="blue darken-2"
											small
											@click="doUnFreeze(item.key_id)"
											v-else
										>
											<v-icon
												light
												left
											>
												mdi-lock-open-check
											</v-icon>
											UnFreeze
										</v-btn>
									</v-col>
									<v-col cols="4">
										<v-btn
											value="justify"
											color="green lighten-2"
											small
											@click="doRefund(item.key_id)"
										>
											<v-icon
												light
												left
											>
												mdi-cash-refund
											</v-icon>
											Refund
										</v-btn>
									</v-col>
									<v-col cols="4">
										<v-btn
											value="justify"
											color="success"
											small
											@click="doUpdateStatus(item.key_id,1)"
											v-if="item.key_status === 0 || item.key_status === 9"
										>
											<v-icon
												light
												left
											>
												mdi-lock-open
											</v-icon>
											Unlock
										</v-btn>
										<v-btn
											value="justify"
											color="warning"
											small
											@click="doUpdateStatus(item.key_id,0)"
											v-if="item.key_status === 1"
										>
											<v-icon
												light
												left
											>
												mdi-lock
											</v-icon>
											Lock
										</v-btn>
									</v-col>
								</v-row>

              <br />
            </template>
            <template v-slot:[`item.ips`]="{ item }">
              <BList :items="item.ips" :flag="true" />
            </template>
            
            <template v-slot:[`item.discord_id`]="{ item }">
              <BList :items="item.discord_id" :flag="false" />
            </template>
            <template v-slot:[`item.key_hwid`]="{ item }">
                <v-select
									v-model="item.key_hwid"
									:items="item.key_hwid"
									label="List HWID"
									multiple
									@change="doUpdateHwid(item)"
									v-if="item.key_hwid.length > 0"
								/>
                <v-btn
                  color="green"
                  text
                  x-small
                  v-if="item.key_hwid.length === 0"
                >
                <v-icon
                  light
                  left
                >
                  mdi-check
                </v-icon>
                  NO HWID
                </v-btn>
                <p v-if="item['hwid_reset'] > 0" class="orange--text">
                    HWID RESET: {{ item['hwid_reset'] }}
                    <v-btn
                        color="orange"
                        small
                        @click="doResetHWIDLimit(item.key_id)"
                    >
                      Reset to 0
                    </v-btn>
                </p>
            </template>
            <template v-slot:[`item.key_package`]="{ item }">
                <v-btn
                    text
                    :color="PackageColors[item.key_package]"
                    medium
                    >
                    {{item.key_package}}
                    <v-icon right v-if="item.key_multihwid">mdi-monitor-multiple</v-icon>
                    <v-icon right v-else>mdi-monitor</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.key_seller`]="{ item }">
              <v-btn
                text
                dark
                small
								@click="doOpenMoveSeller(item)"
              >
              {{ item.key_seller }}
              </v-btn>
            </template>
            <template v-slot:[`item.key_status`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="deep-orange darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    Key Manager
                  <v-icon
                    light
                    right
                  >
                    mdi-pencil
                  </v-icon>
                  </v-btn>
                </template>
                <v-list>
									<v-list-item>
										<v-list-item-title>
											<v-btn value="justify" color="green darken-4" text small @click="doRegen(item.key_id)">
												<v-icon
													light
													left
												>
													mdi-refresh-auto
												</v-icon>
												Re-gen
											</v-btn>
										</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title>
											<v-btn value="justify" color="green" text small @click="doOpenMoveSeller(item)">
												<v-icon
													light
													left
												>
													mdi-account-convert
												</v-icon>
												Change Owner
											</v-btn>
										</v-list-item-title>
									</v-list-item>
                  <v-list-item>
										<v-list-item-title>
											<v-btn value="justify" color="green dark-2" text small @click="doOpenMovePurchaser(item)">
												<v-icon
													light
													left
												>
													mdi-account-convert
												</v-icon>
												Change Purchaser
											</v-btn>
										</v-list-item-title>
									</v-list-item>
                  <v-list-item v-if="item.key_multihwid">
                    <v-list-item-title>
                      <v-btn value="justify"  color="#FBC02D" text small @click="doMultiHWID(item.key_id,0)">
                        <v-icon
                          light
                          left
                        >
                          fas fa-chevron-down
                        </v-icon>
                        Downgrade 1-HWID
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-title>
                      <v-btn value="justify"  color="#7CB342" text small @click="doMultiHWID(item.key_id,1)">
                        <v-icon
                          light
                          left
                        >
                          fas fa-chevron-up
                        </v-icon>
                        Upgrade 2-HWID
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn value="justify" color="error" text small @click="doDelete(item.key_id)">
                        <v-icon
                          light
                          left
                        >
                          mdi-delete
                        </v-icon>
                        Delete
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item.key_details.length > 0">
                    <v-list-item-title>
                      <v-btn value="justify" color="red darken-5" text small @click="doAdd2Blacklist(item.key_id)">
                        <v-icon
                            light
                            left
                        >
                          mdi-cancel
                        </v-icon>
                        BLACKLIST
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item.key_details.length !== 0">
                    <v-list-item-title>
                      <v-btn value="justify" color="primary" text x-small @click="doAddExtraTimeMenu(item.key_id,item.key_login)">
                        <v-icon
                          light
                          left
                        >
                          mdi-update
                        </v-icon>
                        Add Extra Time
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
						<template v-slot:[`item.freeze`]="{ item }">
							{{convertHMS(item.freeze)}}
						</template>
					</v-data-table>
        </v-card>
      </v-flex>
    </template>
    </v-layout>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-menu
      v-model="addExtraMenu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      dark
    >
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="extraTimeAll===true">Add Extra Time For All Key</v-list-item-title>
              <v-list-item-title v-if="extraTimeAll!==true">Add Extra Time: {{extraTimeKey}}</v-list-item-title>
              <v-list-item-subtitle>How many time want to add ? (Count by sec)</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-select
              v-model="extraTimet"
              :items="extraTimetList"
              item-value="value"
              item-text="text"
              label="Type"
            />
            <v-text-field v-model="extraTimex" label="Extra Time">
              <v-icon
                  slot="prepend"
              >
                mdi-clock-outline
              </v-icon>
            </v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field v-model="extraTime" label="Extra Time" readonly>
                <v-icon
                  slot="prepend"
                 >
                  mdi-clock-outline
                </v-icon>
            </v-text-field>
          </v-list-item>
          <v-list-item v-if="extraTimeAll===true">
            <v-select
              v-model="extraSeller"
              :items="sellers"
              label="Seller"
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="extraTimeExpired"
              label="Update for expired keys ?"
              color="red"
              hide-details
              v-if="extraTimeAll === true">
            </v-checkbox>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="addExtraMenu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="doAddExtraTime"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
		<v-dialog
			v-model="BoxNewRegen"
			max-width="600px"
		>
			<v-card flat>
				<v-card-title>KEY DATA</v-card-title>
				<v-card-text>
					<v-textarea
						v-model="NewKeyData"
						label="New Key"
						readonly
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn
						@click="BoxNewRegen = false"
						dark
					>
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-dialog
      v-model="boxData"
      max-width="900px"
    >
      <v-card flat>
        <v-card-title>DATA</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="KeyData"
            dark
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="boxData = false"
            dark
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="loaderData">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
		<v-dialog
			v-model="boxSeller"
			max-width="650px"
		>
			<v-card flat>
				<v-card-title>Change Owner of Key</v-card-title>
				<v-card-text>
					<v-autocomplete
						v-model="new_seller.seller"
						:items="seller_by_id"
						item-value="seller_id"
						item-text="username"
						hint="New Owner"
						persistent-hint />
				</v-card-text>
				<v-card-actions>
					<v-btn
						@click="boxSeller = false"
						dark
						class="mx-2"
					>
						Close
					</v-btn>
					<v-btn
						@click="doChangeSeller()"
						dark
						class="mx-2"
						color="green"
					>
						Change
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-overlay :value="loaderSeller">
				<v-progress-circular
					indeterminate
					size="64"
				></v-progress-circular>
			</v-overlay>
		</v-dialog>
    <v-dialog
        v-model="boxCountry"
        persistent
        max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">Change Country</v-card-title>
        <v-card-text>
          <v-autocomplete
              v-model="setCountryData.country"
              :items="Countries"
              label="Limit by Country ?"
              clearable
              rounded
              hint="Leave it blank if you don't want to limit by country"
              persistent-hint
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="green"
              @click="doUpdateCountry"
              :disabled="setCountryState"
              :loading="setCountryState === setCountryData.key_id"
              small
              class="mt-1 mb-1"
          >
            Update
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="doCancelUpdateCountry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="boxGameLogs"
      max-width="800px"
    >
      <v-card>
        <v-card-title>GAME LOGS</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="game_logs"
            dark
            readonly
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
import CountryJson from '../json/countries.json';
import Notice from '../helpers/notice';
import BList from '../components/List.vue';
export default {
  name: 'VipKey',
  directives: { clipboard },
  components: {
    BList,
  },
  computed: {
      ...mapState('auth', ['status']),
			...mapState({
					alert: state => state.alert
			}),
      Countries(){
        return Object.keys(CountryJson).map((key) => {
          return {
            value: key,
            text: CountryJson[key]
          };
        });
      },
			PurchaserList(){
				let list = [{seller_id: null, username: 'All'}];
				const itemList = this.items.filter(filter=>filter['purchased_id'] > 0);
				const dataList = itemList.map(item=>{
						const index = this.seller_by_id.findIndex(find=>find.seller_id.toString() === item['purchased_id'].toString());
						return {
							seller_id:item['purchased_id'],
							username: (index > -1) ? this.seller_by_id[index]['username']:undefined,
						}
					}
				);
				return list.concat(dataList.filter(find=>find.username !== undefined));
			},
			Items(){
				return this.items.map(item=>{
					// let index = -1;
					// if(item['purchased_id'] > 0){
					// 	index = this.seller_by_id.findIndex(find=>find.seller_id.toString() === item['purchased_id'].toString());
					// }
					return {
						...item,
						//key_seller: (index > -1) ? this.seller_by_id[index].username : item.key_seller,
            purchased_id: this.seller_by_id.filter(find=>find.seller_id.toString() === item['purchased_id'].toString()),
					}
				}
				);
			},
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    doFetchGameLogs: function(key_id){
      this.Overlay = true;
      this.boxGameLogs = false;
      this.game_logs = null;
      fetchData(`${this.$API}/api/Key/game_logs`,{ key_id }).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success === true){
          this.game_logs = json.data['game_logs'];
          this.boxGameLogs = true;
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doCancelUpdateCountry(){
      this.boxCountry = false;
      this.setCountryData = {
        key_id: null,
        country: null,
      }
    },
    doToggleUpdateCountry(item){
      this.setCountryData = {
        key_id: item.key_id,
        country: item.country,
      };
      this.boxCountry = true;
    },
    doUpdateCountry(){
      const item = this.setCountryData;
      this.setCountryState = item.key_id;
      fetchData(`${this.$API}/api/Key/country`,{
        key_id: item.key_id,
        country: item.country,
      }).then((json)=>{
        const {success, msg} = json;
        let message = (msg) ? msg:"Server Error !!!";
        if(success){
          this.doCancelUpdateCountry();
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.setCountryState = null;
      });
    },
    doSetCountryGlobal(item){
      this.setCountryState = item.key_id;
      fetchData(`${this.$API}/api/Key/country`,{
        key_id: item.key_id,
        country: '',
      }).then((json)=>{
        const {success, msg} = json;
        let message = (msg) ? msg:"Server Error !!!";
        if(success){
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
        this.setCountryState = null;
      });
    },
    doChangeBanned(item){
      const app = this;
      return this.$confirm(`Do you really want to change banned of this key ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          app.Overlay = true;
          fetchData(`${this.$API}/api/Key/banned`, {key_id: item['key_id'], banned: !item['banned']}).then((json)=>{
            if(json.status){
              app.success(json.message);
            }else{
              app.error(json.message);
            }
          },(err)=>{
            if(err === 401){
              app.logout();
            }else{
              app.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            app.Overlay = false;
            return app.fetchData();
          });
        }
      });
    },
    doChangeProtection(item){
      const app = this;
      return this.$confirm(`Do you really want to change protection of this key ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          app.Overlay = true;
          fetchData(`${this.$API}/api/Key/protection`, {key_id: item['key_id'], protection: !item['protection']}).then((json)=>{
            if(json.status){
              app.success(json.message);
            }else{
              app.error(json.message);
            }
          },(err)=>{
            if(err === 401){
              app.logout();
            }else{
              app.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            app.Overlay = false;
            return app.fetchData();
          });
        }
      });
    },
    doBlockAllKey(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Key/block-all`).then((json)=>{
        if(json.status){
          this.success(json.message);
        }else{
          this.error(json.message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        return this.fetchData();
      });
    },
    doUnLockAllKey(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Key/unlock-all`).then((json)=>{
        if(json.status){
          this.success(json.message);
        }else{
          this.error(json.message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        return this.fetchData();
      });
    },
		convertHMS(value) {
			const sec = parseInt(value, 10); // convert value to number if it's string
			let hours   = Math.floor(sec / 3600); // get hours
			let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
			let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
			// add 0 if value < 10; Example: 2 => 02
			if (hours   < 10) {hours   = "0"+hours;}
			if (minutes < 10) {minutes = "0"+minutes;}
			if (seconds < 10) {seconds = "0"+seconds;}
			return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
		},
    filterData(){
      const filter = Object.assign({},this.showFilter);
      const cleanFilter = this.clean(filter);
      const {username, status, key_package, created, hwid, first_login, purchaser, playedTime, discord, ip, country} = cleanFilter;
      return {username, status, key_package, created, hwid, first_login, purchaser, playedTime, discord, ip, country};
    },
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Key/get`,request).then((json)=>{
        this.items = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        //this.Overlay = false;
      });
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "All") {
          delete obj[propName];
        }
      }
      return obj
    },
    fetchSellers: function(is_name = true){
			const uri = (is_name) ? 'getName':'get';
      this.Overlay = true;
      fetchData(`${this.$API}/api/Seller/${uri}`).then((json)=>{
        if(is_name) {
					this.sellers = this.sellers.concat(json);
				}else{
					this.seller_by_id = json;
				}
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
		doOpenMoveSeller(item){
			this.new_seller = {seller:item['key_created'], key_id:item['key_id']};
      this.change_mode = "seller";
			this.boxSeller = true;
		},
		doOpenMovePurchaser(item){
			this.new_seller = {seller:item['purchased_id'][0].seller_id, key_id:item['key_id']};
      this.change_mode = "purchaser";
			this.boxSeller = true;
		},
		doChangeSeller(){
			this.loaderSeller = true;
			const {key_id, seller} = this.new_seller;
			const filter_data = this.filterData();
			const request = {
				'key_id': key_id,
				'seller': seller,
			}
			fetchData(`${this.$API}/api/Key/${this.change_mode}`,request).then((json)=>{
				let message = (json.msg) ? json.msg:"Server Error !!!";
				if(json.success == true){
					this.boxSeller = false;
					this.success(message);
					this.fetchData(filter_data);
				}else{
					this.error(message);
				}
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error('SERVER ERROR !!!');
				}
			}).finally(()=>{
				this.loaderSeller = false;
			});
		},
    doSearchData(){
      const Data = this.searchOnData;
      if(Data){
        this.fetchData({Data});
      }else{
        const filted = this.filterData();
        this.fetchData(filted);
      }
    },
    doUpdateHwid: function(item){
      this.Overlay = true;
      let request = {};
      if(item){
        const { key_id, key_hwid } = item;
        request = { key_id, key_hwid };
      }else{
        const key_hwid = [];
        request = { key_hwid };
      }
      const filted = this.filterData();
      fetchData(`${this.$API}/api/Key/hwid`,request).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
            this.success(message);
            this.fetchData(filted);
        }else{
            this.error(message);
        }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doCopyKey: function(item){
      if(item.key_status === 1){
          return item.key_login;
      }
    },
    doParseTime: function(data){
      var dt = new Date(data*1000);
      return dt.toLocaleString();
    },
    doFilter: function(){
      this.fetchData(this.filterData());
    },
		doFreeze: function(key_id){
			this.Overlay = true;
			const filted = this.filterData();
			fetchData(`${this.$API}/api/Key/freeze`,{ key_id }).then((json)=>{
				let message = (json.msg) ? json.msg:"Server Error !!!";
				if(json.success == true){
					this.success(message);
					this.fetchData(filted);
				}else{
					this.error(message);
				}
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error("SERVER ERROR !!!");
				}
			}).finally(()=>{
				this.Overlay = false;
			});
		},
		doUnFreeze: function(key_id){
			this.Overlay = true;
			const filted = this.filterData();
			fetchData(`${this.$API}/api/Key/un-freeze`,{ key_id }).then((json)=>{
				let message = (json.msg) ? json.msg:"Server Error !!!";
				if(json.success == true){
					this.success(message);
					this.fetchData(filted);
				}else{
					this.error(message);
				}
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error("SERVER ERROR !!!");
				}
			}).finally(()=>{
				this.Overlay = false;
			});
		},
		doRegen: function(key_id){
			this.Overlay = true;
			const filted = this.filterData();
			this.$confirm(`Do you really want to re-gen key login ? This data can't restore and new key login not same as old format (Random new key login) !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					fetchData(`${this.$API}/api/Key/re-gen`,{ key_id }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
							this.fetchData(filted);
							this.NewKeyData = json.data;
							this.BoxNewRegen = true;
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error("SERVER ERROR !!!");
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}
			});
		},
		doRefund: function(key_id){
			const filted = this.filterData();
			this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					this.Overlay = true;
					fetchData(`${this.$API}/api/Key/refund`,{ key_id }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
							this.fetchData(filted);
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error('SERVER ERROR !!!');
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}
			});
		},
    doUpdateStatus: function(key_id,key_status){
      const filted = this.filterData();
      this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          this.Overlay = true;
          fetchData(`${this.$API}/api/Key/status`,{ key_id,key_status }).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData(filted);
                }else{
                    this.error(message);
                }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
              //this.Overlay = false;
          });
        }
      });
    },
    doAdd2Blacklist: function(key_id){
      this.Overlay = true;
      const filted = this.filterData();
      this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/Blacklist/add_from_key`,{ key_id }).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
              this.success(message);
              this.fetchData(filted);
            }else{
              this.error(message);
            }
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
          });
        }
      });
    },
    doMultiHWID: function(key_id,key_status){
      this.Overlay = true;
      const filted = this.filterData();
      this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/Key/multi-hwid`,{ key_id,key_status }).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData(filted);
                }else{
                    this.error(message);
                }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
                this.Overlay = false;
          });
        }
      });
    },
    doResetHWIDLimit: function(key_id){
      this.Overlay = true;
      const filted = this.filterData();
      this.$confirm(`Do you really want to reset ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/Key/reset-hwid-limit`,{ key_id }).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    Notice.success({message});
                    this.fetchData(filted);
                }else{
                    this.error(message);
                }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
          });
        }
      });
    },
    doDelete: function(key_id){
      this.Overlay = true;
      const filted = this.filterData();
      this.$confirm(`Do you really want to delete ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/Key/delete`,{ key_id },'DELETE').then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData(filted);
                }else{
                    this.error(message);
                }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
          });
        }
      });
    },
    doAddExtraTime: function(){
      this.Overlay = true;
      this.addExtraMenu = false;
      const filted = this.filterData();
      const extraTime = this.extraTime;
      const key_id = this.extraTimeAll;
      const extraTimeExpired = this.extraTimeExpired;
      const extraSeller = this.extraSeller;
      const data = {key_id,extraTime,extraTimeExpired,extraSeller};
        fetchData(`${this.$API}/api/Key/add-extra-time`,data).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
              if(json.success == true){
                  this.success(message);
                  this.fetchData(filted);
              }else{
                  this.error(message);
              }
        },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
          this.Overlay = false;
        });
    },
    doAddExtraTimeMenu: function(key_id,key_login){
      this.addExtraMenu = true;
      if(key_id && key_login){
        this.extraTimeAll = key_id;
        this.extraTimeKey = key_login;
      }else{
        this.extraTimeAll = true;
        this.extraTimeKey = "";
      }
      this.extraTime = 0;
    },
    doGetData(key_id){
      this.KeyData = '';
      this.loaderData = true;
      this.boxData = true;
      fetchData(`${this.$API}/api/Key/get-data`,{
        key_id:key_id
      }).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
              if(json.success == true){
                this.KeyData = json.data;
              }else{
                this.boxData = false;
                this.error(message);
              }
        },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.boxData = false;
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
          this.loaderData = false;
        });
    },
    doCountExtraTime(){
      const t = this.extraTimet; const tt = this.extraTimex;
      if(tt > 0){
        let time = 0;
        switch(t){
          case 0:
              time = tt*60*60*24;
            break;
          case 1:
              time = tt*60*60;
            break;
          case 2:
            time = tt*60;
            break;
        }
        this.extraTime = time;
      }
    },
    doFetchAllData(){
      this.loader = true;
      let dataList = [this.fetchSellers(), this.fetchSellers(false), this.fetchData()];
      Promise.all(dataList).finally(()=>{
        this.loader = false;
      });
    }
  },
  data : () => ({
    change_mode: 'seller',
    boxGameLogs: false,
    game_logs: null,
    boxCountry: false,
    setCountryData:{
      key_id: null,
      country: null,
    },
    setCountryState: null,
		BoxNewRegen: false,
		NewKeyData: null,
    extraTimetList:[{value:0,text:'Days'},{value:1,text:'Hours'},{value:2,text:'Minutes'}],
    extraTimet:0,
    extraTimex:0,
    searchOnData:'',
    showFilter: {
      country: null,
      username: "All",
      status: null,
      key_package: "All",
      created: null,
      hwid: null,
			first_login: null,
			purchaser: null,
			playedTime: null,
      discord: null,
      ip: null,
      country: null,
    },
    extraTimeAll:true,
    addExtraMenu: false,
    extraTimeKey: "",
    extraTime: 0,
    extraSeller:'All',
    extraTimeExpired: false,
    modifyMode: false,
    Overlay: false,
    dialog: false,
    search: '',
    headers: [
            { text: 'Key', align: 'right', sortable: false,value: 'key_login',width:"auto" },
            { text: 'Discord', align: 'center', sortable: true,value: 'discord_id',width:"auto" },
            { text: 'IP', align: 'center', sortable: true,value: 'ips',width:"auto" },
            { text: 'Country', align: 'center', sortable: true,value: 'country',width:"auto" },
            { text: 'PC Data', align: 'center', sortable: true,value: 'pcinfor',width:"auto" },
						{ text: 'Freeze Time', align: 'center', sortable: true,value: 'freeze',width:"auto" },
            { text: 'P / B', align: 'center', sortable: false, value: 'protection',width:"auto" },
            { text: 'HWID', align: 'left', sortable: true,value: 'key_hwid',width:"auto" },
            { text: 'Package', align: 'center', sortable: false,value: 'key_package',width:"auto" },
            { text: 'Seller', align: 'center', sortable: false,value: 'key_seller',width: "auto"},
            { text: 'Purchaser', align: 'center', sortable: false,value: 'purchased_id',width: "auto"},
            { text: 'Modify', align: 'center', sortable: false,value: 'key_status',width: "auto" },
        ],
    items: [],
    sellers: ["All"],
    updateMode: false,
    packages:['All','2HOUR','1DAY','7DAY','15DAY','30DAY'],
    topupSellerData: {},
    topupMethod: "",
    FilterUsed: [{title:"All",search:null},{title:"Unused",search:1},{title:"Used",search:2},{title:"Expired",search:3},{title:"Locked",search:4},{title:"Blacklisted",search:5},{title:"Regenerated",search:6},{title:"Freezed",search:7}],
    FilterHWID: [{title:"All",search:null},{title:"Single",search:false},{title:"Multi",search:true}],
    FilterCreated: [{title:"All",search:null},{title:"1 Day",search:86400},{title:"1 Week",search:604800}],
    PackageColors: {"1DAY":"cyan lighten-3","7DAY":"cyan lighten-2","15DAY":"cyan darken-2","30DAY":"cyan darken-4","2HOUR":"green darken-1"},
    KeyData: '',
    loaderData: false,
    boxData: false,
    counter:undefined,
		new_seller: {},
		seller_by_id: [],
		boxSeller: false,
		loaderSeller: false,
  }),
  watch:{
    items: function(){
      this.Overlay = false;
    }
  },
  beforeDestroy(){
    if(this.counter) clearInterval(this.counter);
    this.counter = undefined;
  },
  mounted(){
    this.doFetchAllData();
    this.counter = setInterval(this.doCountExtraTime,1000);
  }
}
</script>
<style>
</style>